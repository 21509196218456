import React from "react"
import Layout from "../components/layout"
import SEO from "../utils/seo"

const Inscripcions = () => {
  return (
    <Layout title="Inscripcions">
      <SEO title="Inscripcions" />
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLScZ__uFpvLOtbXMWH-yEmir_TXHqu_ZRa8E7_AqBEFragL0Hw/viewform?usp=preview"
          width="800"
          title="inscripcions"
          height="800"
        ></iframe>
      </div>
    </Layout>
  )
}

export default Inscripcions
